import React, { useEffect } from "react";
import Banner from "../components/home/banner";
import About from "../components/home/about";
import Expertise from "../components/home/expertise";
import Brand from "../components/home/brand";
import Future from "../components/home/future";
import VoicesTrust from "../components/home/voicesTrust";
import Team from "../components/home/team";
import Faq from "../components/home/faq";
import SubmitForm from "../components/home/submitForm";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Banner />
      <About />
      <Expertise />
      {/* <Brand /> */}
      <Future />
      {/* <VoicesTrust /> */}
      {/* <Team /> */}
      <Faq />
      <SubmitForm />
    </>
  );
};

export default Home;
