import React, { useEffect, useState } from "react";
import "./blogscontent.scss";
import img2 from "../../../assets/images/about-blue-glow.png";
import topimg from "../../../assets/images/pic1.png";
import profilepic from "../../../assets/images/profilepic.png";
import hoverimg from "../../../assets/images/cardhoverimg.png";
import inner1 from "../../../assets/images/inner1.webp";
import inner2 from "../../../assets/images/inner2.webp";
import inner3 from "../../../assets/images/inner3.webp";
import inner4 from "../../../assets/images/inner4.webp";
import inner5 from "../../../assets/images/inner5.webp";
import inner6 from "../../../assets/images/inner6.webp";
import inner7 from "../../../assets/images/inner7.webp";
import inner8 from "../../../assets/images/inner8.webp";
import inner9 from "../../../assets/images/inner9.webp";
import inner10 from "../../../assets/images/inner10.webp";
import Blogscard from "./blogscard";
import CardContent from "./cardContent";
import Blogbanner from "../blogBanner/blogbanner";
import { cardData } from "../../../utills/mock";
import hover2 from "../../../assets/images/cardhover2.png";
import AOS from "aos";
import "aos/dist/aos.css";

const BlogsContent = () => {
  const [activeComponent, setActiveComponent] = useState("");
  const handleCardClick = (stateValue) => {
    setActiveComponent(stateValue);
  };

  const handleGoBack = () => {
    setActiveComponent("");
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Blogbanner goBack={handleGoBack} showGoBack={activeComponent !== ""} />
      {activeComponent === "" && (
        <div className="blogsContent">
          <div
            className="blogs-top-container"
            onClick={() => {
              setActiveComponent("Metaloop");
            }}
          >
            <div data-aos="zoom-in-right" className="blog-top-leftcontainer ">
              <img src={topimg} alt="" />
              <div className="hover-img">
                <img src={hoverimg} alt="" />
              </div>
              <div className="hover-back-img">
                <img src={hover2} alt="" />
              </div>
            </div>
            <div data-aos="zoom-in-left" className="blog-top-rightcontainer">
              <h2>
                Metaloop Solutions: Creating a Legacy in Digital Innovation
              </h2>
              <p>
                In the dynamic realm of digital transformation, few names echo
                the promise of a lasting impact quite like Metaloop Solutions.
                This emerging force in the tech domain is more than just a
                company; it represents a movement.....
              </p>
              {/* <div className="profile-section">
                <div className="profile-left-container">
                  <img src={profilepic} alt="" />
                </div>
                <div className="profile-right-container">
                  <h2>John wick</h2>
                  <p>Jun 27, 2024</p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="cards-containermain">
            {cardData.map((card) => (
              <Blogscard
                key={card.id}
                image={card.image}
                heading={card.heading}
                content={card.content}
                statevalue={card.statevalue}
                setActiveComponent={setActiveComponent}
                handleCardClick={() => handleCardClick(card.statevalue)}
              />
            ))}
          </div>
        </div>
      )}

      {activeComponent === "Metaloop" && (
        <CardContent
          image={inner1}
          animate="zoom-out-right"
          heading="Metaloop Solutions"
          subheading="Metaloop Solutions: Creating a Legacy in Digital Innovation"
          content1="In the dynamic realm of digital transformation, few names echo the promise of a lasting impact quite like Metaloops Solutions. This emerging force in the tech domain is more than just a company; it represents a movement, a symphony of solutions meticulously designed to redefine the parameters of digital innovation."
          content2="Metaloops Solutions manoeuvres through the ever-shifting currents of the digital landscape with a vision that transcends the ordinary. It's not simply about coding or developing software; it's about composing a narrative of technological excellence that surpasses the constraints of the present.."
          content3="As Metaloops Solutions embarks on this transformative journey, it extends an invitation to join in shaping the future. This isn't just a service provider; it's a partner in your digital evolution. Together, let's craft a legacy where innovation converges with inspiration, and the digital landscape undergoes a perpetual transformation. Welcome to the era of Metaloops Solutions where solutions are not merely created; legacies are meticulously created."
        />
      )}
      {activeComponent === "web3" && (
        <CardContent
          image={inner2}
          heading="Web 3.0"
          subheading="Web 3.0: The Future of the Internet Unveiled"
          content1="Unveil the future of the internet with 'Web 3.0.' Explore the decentralized, interactive landscape that is reshaping online experiences, promising a user-centric web revolution."
          content2="Web 3.0 transcends being a buzzword; it's a reimagination of the internet's DNA. This phase marks a departure from centralized systems, introducing decentralized, user-centric protocols. The goal is to create an internet where users wield more control over their data, fostering transparency and enabling peer-to-peer interactions."
          content3="In the Web 3.0 landscape, power dynamics are shifting. Decentralized applications (DApps) and blockchain technology challenge traditional models of centralized control. With advancements in artificial intelligence and machine learning, a deeper understanding of context, intent, and content is achieved, providing users with a more personalized and intelligent digital experience. Interoperability breaks down silos, creating a more connected digital ecosystem."
          content4="Web 3.0 isn't a distant vision; it's already making waves in decentralized finance (DeFi), non-fungible tokens (NFTs), and the metaverse, promising a more inclusive, secure, and user-centric internet experience. As we step into this era, embracing change, adapting to new technologies, and actively participating in the ongoing dialogue will be crucial. The journey ahead is filled with possibilities and innovations that will redefine how we engage with the digital world, making Web 3.0 not just the next chapter but a whole new narrative in the story of the internet. Buckle up as we embark on this thrilling journey into the digital frontier, where the future of the internet unfolds before our eyes."
        />
      )}
      {activeComponent === "innovation" && (
        <CardContent
          image={inner3}
          heading="Innovation in Sales"
          subheading="Innovation in Sales: Adapting to the Digital Selling Landscape"
          content1="Step into the world of modern sales, where cool tech is changing the game! Imagine talking to super-smart computer helpers when you are shopping, or businesses using fancy tools to understand exactly what you like. Thats the new way of selling stuff – all thanks to awesome innovations."
          content2="Here is the secret: technology and sales are now best buddies. There are smart computer systems, clever number-crunching tools, and even robots making things super smooth. Its not just about selling things; its about making your shopping experience special and unique."
          content3="But guess what? Even with all this tech magic, good old human connection is still super important! In this world of tech wonders, being real, making friends, and understanding what you want are still the most powerful things. So, as we explore this cool new way of selling stuff, lets remember it is not just about the fancy gadgets; its about mixing cool tech with the timeless art of making friends. Welcome to the future of sales – where tech and the fun of shopping come together for something amazing!"
          content4="This digital sales journey isn it just for big companies – its for everyone! Small businesses and creative minds can hop on this tech train too. With simple tools and a dash of imagination, anyone can join the fun of selling in this digital age. So, whether you are a big brand or a local hero, the future of sales is an open playground for everyone to explore. Get ready to mix your unique touch with a sprinkle of tech – the perfect recipe for success in the exciting world of digital selling!"
        />
      )}

      {activeComponent === "it-Solutions" && (
        <CardContent
          image={inner4}
          heading="Customized IT Solutions"
          subheading="Customized IT Solutions: Tailoring Technology to Business Needs"
          content1="In today is dynamic business landscape, the notion of one-size-fits-all technology is fading away, making room for the era of customized IT Solutions. In this fast-paced digital symphony, businesses are seeking tailored excellence to align technology seamlessly with their unique needs and challenges. No longer content with off-the-shelf Solutions, companies are embracing the power of bespoke IT strategies that transform technology into a strategic ally rather than a constraint."
          content2="Think of Customized IT Solutions as bespoke suits for businesses intricately designed to highlight strengths, address challenges, and ensure a perfect fit for the digital journey. The shift away from a generic approach marks a paradigm change, empowering businesses to stand out in the digital crowd by leveraging technology crafted specifically to amplify their strengths and fuel growth."
          content3="The beauty of customization lies in its transformative potential. Whether streamlining operations, bolstering cybersecurity measures, or fostering innovation through tailor-made software, customized IT Solutions empower businesses to embrace their uniqueness. Its not just about meeting present needs; its about future-proofing enterprises, equipping them for the challenges and opportunities that lie ahead."
          content4="In this digital couture where technology meets bespoke craftsmanship, businesses are invited to rethink their approach. Customized IT Solutions represent more than adopting solutions; they embody a tailored technological narrative that resonates uniquely with each business's heartbeat. Welcome to the era of Customized IT Solutions where technology isn't just a tool but a personalized catalyst for unparalleled success."
        />
      )}

      {activeComponent === "gamedevelopment" && (
        <CardContent
          image={inner5}
          heading="Game Development"
          subheading="Game Development Trends: From Casual Gaming to Esports"
          content1="In the dynamic realm of game development, industry trends play a pivotal role in shaping the gaming experience. From the widespread popularity of casual gaming to the explosive rise of esports, the landscape is in constant flux, pushing the boundaries of digital entertainment. Lets delve into these trends and explore how they are transforming the way we play, compete, and engage in the world of gaming."
          content2="Casual gaming has transcended its niche origins to become a global phenomenon, attracting a diverse audience. The simplicity and accessibility of casual games, particularly on mobile platforms, have contributed to their widespread appeal. Developers are capitalizing on this trend, creating innovative and engaging experiences that appeal to players of all ages."
          content3="Cross-platform gaming has emerged as a trend that prioritizes inclusivity and flexibility. Players can seamlessly switch between devices, from consoles to PCs to mobile devices, without losing progress. This trend enhances accessibility and enriches the multiplayer experience, fostering a gaming community that transcends hardware boundaries."
          content4="In conclusion, game development trends continue to redefine the gaming landscape, making it an exciting time for both developers and players. From the simplicity of casual gaming to the intensity of esports and the immersive potential of VR and AR, these trends showcase the industrys adaptability and innovation. As technology advances, the future of gaming promises even more incredible experiences, providing something for everyone, whether you are a casual player, an esports enthusiast, or a fan of immersive technologies."
        />
      )}
      {activeComponent === "navigatefuture" && (
        <CardContent
          image={inner6}
          heading="Navigating the Future"
          subheading="Navigating the Future: the Power of AI with Metaloop Solutions"
          content1="In an era defined by technological leaps, artificial intelligence (AI) has emerged as a transformative force, reshaping industries and redefining business landscapes. At the forefront of this AI revolution stands Metaloop Solutions, pioneering innovation and offering a suite of comprehensive services to empower organizations. This blog delves into the profound impact of AI on businesses, exploring Metaloop Solutions cutting-edge services that span deep learning, natural language processing (NLP), machine learning, cognitive systems, planning and optimization, and speech recognition."
          content2="As businesses navigate an increasingly complex and competitive environment, embracing AI has become more than a strategic choice, its an imperative. Metaloop Solutions recognizes the urgency for organizations to harness the potential of AI, and their commitment to innovation positions them as a catalyst for digital transformation."
          content3="Natural Language Processing (NLP) is another key component of Metaloop Solutions AI arsenal. By enabling machines to understand, interpret, and generate human-like text, NLP facilitates seamless communication between humans and computers. This not only enhances user experiences but also unlocks the potential for automating language-related tasks."
          subheading2="Cognitive Systems: Augmenting Human Intelligence:"
          content4="In the quest for AI-driven excellence,  Metaloop Solutions introduces cognitive systems that emulate human cognitive functions. These systems process information, learn from experience, and adapt to changing circumstances. By blending machine intelligence with human intuition, cognitive systems amplify decision-making capabilities, driving efficiency and innovation."
          subheading3="Planning and Optimization: Precision in Every Move:"
          content5="Precision and efficiency are the cornerstones of success, and  Metaloop Solution's AI services extend to planning and optimization. Through advanced algorithms and optimization techniques, businesses can streamline processes, allocate resources effectively, and enhance overall operational efficiency."
          subheading4="Speech Recognition: Giving Voice to Technology:"
          content6="The ability to comprehend and respond to human speech is a game-changer, and  Metaloop Solution's expertise in speech recognition technology amplifies this capability. From virtual assistants to voice-activated commands, speech recognition not only enhances user interfaces but also finds applications in industries ranging from healthcare to customer service."
          content7="In the dynamic landscape of technological evolution,  Metaloop Solutions stands as a beacon of AI innovation, offering a comprehensive suite of services that redefine the way businesses operate. By embracing deep learning, NLP, machine learning, cognitive systems, planning and optimization, and speech recognition, organizations can embark on a transformative journey toward increased efficiency, enhanced decision-making, and unparalleled competitiveness. The future belongs to those who seize the power of AI, and with  Metaloop Solutions as a partner, businesses can navigate this future with confidence and success."
        />
      )}
      {activeComponent === "blockchain" && (
        <CardContent
          image={inner7}
          heading="Metaloop Solutions Pioneering the Future"
          subheading="Metaloop Solutions Pioneering the Future of Blockchain Development"
          content1="Welcome to the world of limitless possibilities, where innovation meets technology. At Metaloop Solutions, we pride ourselves on being at the forefront of blockchain development, offering a diverse range of services to empower our clients in navigating the dynamic landscape of decentralized Solutions. In this article, we will delve into the cutting-edge services we provide, highlighting how our expertise can transform ideas into reality."
          content2="Metaloop Solutions understands the importance of enterprise-level blockchain Solutions. Our expertise in Hyperledger ensures that businesses can harness the benefits of private, permissioned blockchains tailored to their specific needs. From supply chain management to identity verification, we offer comprehensive Hyperledger Solutions, bringing efficiency and transparency to your business processes."
          content3="Smart contracts are the backbone of decentralized applications, enabling trustless and automated transactions. Metaloop Solutions excels in smart contract development, ensuring that your agreements are executed seamlessly and securely on the blockchain. Trust us to turn your concepts into self-executing code, reducing the need for intermediaries and enhancing overall efficiency."
          content4="Non-fungible tokens (NFTs) have taken the digital world by storm, revolutionizing the way we value and exchange digital assets. Metaloop Solutions is your partner in NFT marketplace creation, providing a platform for artists, musicians, and content creators to tokenize and monetize their work. Join the NFT revolution with our expert guidance."
          content5="Metaloop Solutions is more than just a blockchain development service provider; we are architects of the future, helping our clients navigate the exciting possibilities that decentralized technologies bring. Whether you are venturing into Ethereum development, exploring Hyperledger Solutions, or diving into the world of NFTs, our team is ready to turn your vision into reality. Join us on this journey of innovation, and lets shape the future together"
        />
      )}
      {activeComponent === "metaverse" && (
        <CardContent
          image={inner8}
          heading="Metaloop Solutions"
          subheading="Masters of the Metaverse: Forging Digital Destiny in the Next Era"
          content1="In the vast expanse of the digital realm, a revolutionary concept has taken center stage, captivating the imagination of individuals and businesses alike the Metaverse. At Metaloops Solutions, we stand as your trusted partner on this exhilarating journey, committed to guiding you through the intricacies of this rapidly evolving digital reality. This blog unveils the diverse spectrum of services we offer."
          content2="Metaloop Solutions is more than just a service provider; we are your trusted partner on the journey into the Metaverse. With a commitment to excellence and innovation, we offer a range of services tailored to meet the unique needs of businesses and creators venturing into this exciting new world."
          content3="Metaloop Solutions’ comprehensive suite of services is designed to empower both businesses and creators to thrive in the Metaverse. Whether you're looking to establish a virtual presence, create decentralized communities, or monetize digital assets through NFTs, our team is dedicated to turning your Metaverse aspirations into reality."
          content4="The Metaverse is not static; it's a constantly evolving digital reality. Metaloop Solutions stays ahead of the curve, continuously adapting and innovating to ensure our clients remain at the forefront of this transformative landscape."
          content5="As the Metaverse unfolds before us, Metaloop Solutions emerges as the beacon guiding businesses and creators through this exhilarating digital frontier. With a commitment to excellence, innovation, and a comprehensive suite of services, we are poised to shape the future of the Metaverse alongside our clients. Embrace the possibilities, embark on the journey, and let Metaloop Solutions be your trusted companion in this remarkable digital adventure."
        />
      )}

      {activeComponent === "machine-learning" && (
        <CardContent
          image={inner9}
          heading="Machine Learning"
          subheading="Machine Learning: Transforming Data into Insights"
          content1="In today is data-driven world, the sheer volume of information available can be overwhelming. Companies and individuals alike are seeking ways to extract meaningful insights from this data to make informed decisions. This is where machine learning comes into play, revolutionizing the process of transforming raw data into valuable insights."
          content2="Machine learning is a subset of artificial intelligence that focuses on the development of algorithms and statistical models. Unlike traditional programming, where explicit instructions are provided to perform a task, machine learning systems learn from data. These systems can identify patterns, make predictions, and continuously improve their performance over time."
          content3="Data processing and cleaning are essential steps before machine learning algorithms can work their magic. This involves cleaning and organizing the data to ensure accuracy and consistency. Machine learning models are sensitive to the quality of input data, making this step crucial."
          content4="Feature extraction involves selecting the most relevant information from the raw data, reducing complexity while retaining essential insights. In the training phase, machine learning models learn from historical data, identifying patterns, relationships, and dependencies that enable them to make predictions or classifications. The accuracy of these models improves as they are exposed to more data."
          content5="In conclusion, machine learning is a powerful tool for transforming data into actionable insights. As technology continues to evolve, so does our ability to harness the potential of machine learning, unlocking new possibilities across various industries."
        />
      )}
      {activeComponent === "nfts" && (
        <CardContent
          image={inner10}
          heading="NFTs Unleashed"
          subheading="NFTs Unleashed: Riding the Digital Wave of Tokenized Creativity"
          content1="In the dynamic landscape of the digital age, a revolutionary trend has surfaced, reshaping our perception and interaction with art and creativity. This phenomenon is none other than Non-Fungible Tokens (NFTs), introducing a wave of innovation and fundamentally altering how we assess and trade digital assets."
          content2="At the core of this transformative movement lies the concept of tokenization. NFTs, unique digital tokens, serve as markers of ownership or proof of authenticity for specific digital items or content. Unlike conventional currencies or cryptocurrencies, each NFT possesses distinct characteristics, creating a digital proof of ownership that can be purchased, sold, or exchanged on blockchain platforms."
          content3="One of the most captivating aspects of NFTs is their application within the creative sphere. Artists, musicians, and digital creators are now venturing into unexplored territories to showcase and monetize their creations. The tokenization of digital art has paved the way for a global marketplace, enabling creators to directly connect with a vast audience without being constrained by geographical boundaries."
          content4="In summary, NFTs transcend being mere tokens; they epitomize the evolving relationship between technology and creativity. Riding the digital wave of tokenized creativity, NFTs possess the capacity to redefine how we conceive, value, and engage with art in the expansive digital realm. Amidst this uncharted territory, one certainty emerges – the impact of NFTs on the creative world is a transformative force that will endure. "
        />
      )}

      <div className="blogsbackground">
        <img src={img2} alt="" />
      </div>
    </div>
  );
};

export default BlogsContent;
