import React, { useEffect } from "react";
import "./blogscontent.scss";
import card1 from "../../../assets/images/card1.png";
import profilepic from "../../../assets/images/profilepic.png";
import hoverimg from "../../../assets/images/cardhoverimg.png";
import hover2 from "../../../assets/images/cardhover2.png"

import AOS from "aos";
import "aos/dist/aos.css";
const Blogscard = ({
  image,
  heading,
  content,
  statevalue,
  setActiveComponent,
}) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      className="blogs-card-container"
      data-aos="zoom-in"
      onClick={() => {
        setActiveComponent(statevalue);
      }}
    >
      <div className="blog-card-topcontainer">
        <img src={image} alt="" />
        <div className="hover-img">
          <img src={hoverimg} alt="" />
        </div>
        <div className="hover-background">
          <img src={hover2} alt="" />
        </div>

      </div>
      <div className="blog-card-bottomcontainer">
        <h2>{heading}</h2>
        <p>{content}</p>
        {/* <div className="card-profile">
          <div className="card-profile-left">
            <img src={profilepic} alt="" />
          </div>
          <div className="card-profile-right">
            <h2>John wick</h2>
            <p>Jun 27, 2024</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Blogscard;
